// import axios from 'axios';
import { openaiApiEndpoint, openaiApiKey, openaiApiModel, openaiApiMaxTokens } from '@/openai.config';

// const axiosConfig = {
//   headers: {
//     'Content-Type': 'application/json',
//     'Authorization': `Bearer ${openaiApiKey}`,
//   },
//   responseType: 'stream',
// };

export const getAssistantResponse = async (conversation, streamAssistantResponse) => {

  const apiRequestHeaders = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${openaiApiKey}`,
  };

  const apiRequestBody = {
    model: openaiApiModel,
    messages: conversation,
    temperature: 0.6,
    max_tokens: openaiApiMaxTokens,
    n: 1,
    stop: null,
    stream: true,
  };

  const response = await fetch(openaiApiEndpoint, {
      method: "POST",
      cache: "no-cache",
      keepalive: true,
      headers: apiRequestHeaders,
      body: JSON.stringify(apiRequestBody)
  });
  
  const reader = response.body.getReader();
  
  while (reader) {
    const { value, done } = await reader.read();
    if (done) break;

    const rawVal = new TextDecoder().decode(value);
    const splitVal = rawVal.split("\n");
    for (const val of splitVal) {
      if (val == 'data: [DONE]') {
        break;
      } else if (!val == '') {
        const data = val.replace('data: ', '');
        const jsonData = JSON.parse(data);
        const choicesDelta = jsonData.choices[0].delta;
        if ('content' in choicesDelta) {
          await streamAssistantResponse(choicesDelta.content);
        }
      }
    }
  }

  // const response = await axios.post(
  //   openaiApiEndpoint,
  //   {
  //     model: openaiApiModel,
  //     messages: conversation,
  //     temperature: 0.7,
  //     max_tokens: openaiApiMaxTokens,
  //     n: 1,
  //     stop: null,
  //     stream: true,
  //   },
  //   axiosConfig,
  // );

  // let es = new EventSource(openaiApiEndpoint);
  //   es.addEventListener('data', event => {
  //   let data = JSON.parse(event.data);
  //   this.pets.push(data);
  // }, false);

  // console.log(response.data)
  // response.data.on('data', console.log);

  // stream.on('data', data => {
  //     console.log(data);
  // });

  // stream.on('end', () => {
  //     console.log("stream done");
  // });

  // return response.data.choices[0].message.content.trim();
};
