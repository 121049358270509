import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import { createRouter, createWebHistory } from 'vue-router';
import MyComponent from './components/MyComponent.vue';
import Ignatius from './components/Ignatius.vue';
import Upcoming from './components/Upcoming.vue';
import Recent from './components/Recent.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: MyComponent },
    { path: '/upcoming', component: Upcoming },
    { path: '/recent/:audience?', component: Recent },
    { path: '/ignatius', component: Ignatius },
  ],
});

const app = createApp(App);

app.use(store);
app.use(router);

app.mount('#app');
