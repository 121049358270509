<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  components: {
  },
};
</script>

<style>
/* Your app styles here */
</style>
