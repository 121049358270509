import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default createStore({
  state: {
    conversation: [],
  },
  mutations: {
    ADD_MESSAGE(state, message) {
      console.log(state.conversation)
      state.conversation.push(message);
    },
    UPDATE_MESSAGE(state, { index, content }) {
      state.conversation[index].content += content;
    },
    CLEAR_CONVERSATION(state) {
      state.conversation = [];
    },
  },
  actions: {
    addMessage({ commit }, message) {
      commit('ADD_MESSAGE', message);
    },
    clearConversation({ commit }) {
      commit('CLEAR_CONVERSATION');
    },
    updateMessage({ commit }, { index, content }) {
      commit('UPDATE_MESSAGE', { index, content });
    },
  },
  getters: {
    conversation: (state) => state.conversation,
  },
  plugins: [vuexLocal.plugin],
});
