<template>
  <section class="conversation-area">
    <div v-if="conversation.filter(convo => convo.role != 'user').length" class="conversation" ref="conversationContainer">
      <div v-for="(msg, index) in conversation.filter(convo => convo.role != 'user')" :key="index" :class="msg.role">
        <div class="msg-wrapper">
          <span class="msg-role">Recent</span>
          <span class="ellipsis" v-if="msg == ''">...</span>
          <span class="msg-content" v-else v-html="msg.content.replace(/(?:\r\n|\r|\n)/g, '<br>')"></span>
        </div>
      </div>
    </div>
    <div v-else class="conversation" ref="conversationContainer">
      <div class="assistant">
        <div class="msg-wrapper">
          <span class="msg-role">Loading</span>
          <span class="msg-content ellipsis">...</span>
        </div>
      </div>
    </div>
  </section>
  <section class="entry-area">
    <div class="entry-wrapper">
      <button @click="summarizeEvents">Refresh Data</button>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import { ref, computed, onMounted, toRaw, watch, nextTick } from 'vue';
import { useStore } from 'vuex';
import { recentDays } from '@/openai.config';
import { systemMessagesRecent } from '@/openai.config';
import { getAssistantResponse } from '@/api/openai';
import { useRoute } from 'vue-router'

export default {
  name: 'RecentEvents',

  setup() {
    const store = useStore();
    const conversation = computed(() => store.getters.conversation);
    const newMessage = ref('');
    const isLoading = ref(false);
    const messageInput = ref(null);
    const conversationContainer = ref(null); // Add conversationContainer ref
    const route = useRoute()

    const scrollToBottom = async () => {
      await nextTick(); // Wait for the DOM to update
      window.scrollTo({
        left: 0,
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    };

    watch(conversation, () => {
      scrollToBottom(); // Trigger the scroll to bottom whenever the conversation changes
    }, { deep: true }); // Add deep:true to watch changes in the conversation array

    onMounted(async () => {
      console.log('Recent.vue mounted');
      summarizeEvents();
      
    });

    function removeTags(str) {
      if ((str===null) || (str===''))
          return false;
      else
          str = str.toString();
           
      // Regular expression to identify HTML tags in
      // the input string. Replacing the identified
      // HTML tag with a null string.
      return str.replace( /(<([^>]+)>)/ig, '');
    }

    const summarizeEvents = async () => {
      resetConversation();
      let tempSystemMessages = new Array();
      let audience = route.params.audience ?? 'students'

      tempSystemMessages.push({
        role: 'system',
        content: `PRIMARY AUDIENCE: ${audience}`
      });

      // Make the axios GET request
      let announcementData = null;
      try {
        const response = await axios.get('https://www.jesuitnola.org/wp-json/wp/v2/posts?_fields%5B%5D=title&_fields%5B%5D=content&categories=134&per_page=' + recentDays);
        announcementData = response.data;
      } catch (error) {
        console.error('Error while making GET request:', error);
      }

      // Format the API response and add it as a system message
      if (announcementData) {
        const announcementInfo = announcementData.map(event => `Title: ${event.title.rendered}, Content: ${removeTags(event.content.rendered)}`).join('\n');
        console.log(announcementInfo)
        tempSystemMessages.push({
          role: 'system',
          content: `ANNOUNCEMENT INFORMATION: \r\n ${announcementInfo}`
        });
      }

      // Make the axios GET request
      let newsData = null;
      try {
        const response = await axios.get('https://www.jesuitnola.org/wp-json/wp/v2/posts?_fields%5B%5D=title&_fields%5B%5D=date&_fields%5B%5D=content&categories=8&per_page=3');
        newsData = response.data;
      } catch (error) {
        console.error('Error while making GET request:', error);
      }

      // Format the API response and add it as a system message
      if (newsData) {
        const newsInfo = newsData.map(article => `Title: ${article.title.rendered}, Date: ${article.date}, Content: ${removeTags(article.content.rendered)}`).join('\n\n');
        console.log(newsInfo)
        tempSystemMessages.push({
          role: 'system',
          content: `RECENT NEWS: \r\n ${newsInfo}`
        });
      }

      if(tempSystemMessages.length) {
        // Call OpenAI API with a system message on mount
        await pushNewMessage(systemMessagesRecent, tempSystemMessages);
      }
    }

    const resetConversation = () => {
      store.dispatch('clearConversation');
    };

    const pushNewMessage = async (systemPrompt, tempSystemMessages) => {
      await nextTick(); // Wait for the DOM to update

      // Create a copy of the conversation without reactivity
      const rawConversation = systemPrompt.concat(toRaw(tempSystemMessages));

      isLoading.value = true;
      const assistantMessage = {
        role: 'assistant',
        content: '',
      };
      const assistantMessageIndex = conversation.value.length;
      store.dispatch('addMessage', assistantMessage);
       try {
        const streamAssistantResponse = async (responseChunk) => {
          store.dispatch('updateMessage', { index: assistantMessageIndex, content: responseChunk });
        };

        await getAssistantResponse(rawConversation, streamAssistantResponse);
      } catch (error) {
        console.error('Error while calling OpenAI API:', error);
      }
      isLoading.value = false;
    };

    return {
      conversation,
      newMessage,
      pushNewMessage,
      resetConversation,
      summarizeEvents,
      isLoading,
      messageInput,
      conversationContainer,
    };
  },
};
</script>

<style>

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  background-color: #444654;
}

.conversation-area {
  width: 100%;
  margin: 0 auto;
}

.conversation {
  width: 100%;
  padding-bottom: 116px;
  margin-bottom: 0;
  overflow-y: auto;
}

.user,
.assistant {
  padding: 2rem;
  width: 100%;
  color: rgb(209, 213, 219);
  margin-bottom: 0.5rem;
  line-height: 1.5;
  font-family: sans-serif;
}

.user .msg-wrapper,
.assistant .msg-wrapper {
  display: flex;
  max-width: 750px;
  margin: 0 auto;
}

.msg-role {
  display: block;
  width: 6rem;
  margin-left: -7rem;
  text-transform: capitalize;
  font-weight: 700;
  color: #ffffff;
  text-align: right;
}

.msg-content {
  display: block;
  margin-left: 1rem;
  width: calc(100% - 6rem);
}

.user {
  background-color: rgb(52, 53, 65);
}

.assistant {
  background-color: rgba(0,0,0,0);
}

@keyframes ellipsis {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ellipsis {
  display: inline-block;
  font-weight: bold;
  animation: ellipsis 1.5s steps(3, start) infinite;
}

.entry-area {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgb(52, 53, 65);
  padding: 1rem;
  padding-bottom: 2rem;
}

.entry-wrapper {
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
}

textarea {
  width: 100%; /* Make textarea 100% wide */
  resize: none; /* Disable resize by dragging the corner */
  overflow: hidden;
  padding: 0.85rem 1rem;
  border: 0;
  border-radius: 6px;
  color: rgb(255,255,255);
  background-color: rgba(255,255,255,0.1);
  appearance: none;
  -webkit-appearance: none;
  outline: 0;
  font-size: 1rem;
  line-height: 1.5;
}
</style>
