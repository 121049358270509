<template>
  <section class="conversation-area">
    <div v-if="conversation.filter(convo => convo.role != 'user').length" class="conversation" ref="conversationContainer">
      <div v-for="(msg, index) in conversation.filter(convo => convo.role != 'user')" :key="index" :class="msg.role">
        <div class="msg-wrapper">
          <span class="msg-role">Upcoming</span>
          <span class="ellipsis" v-if="msg == ''">...</span>
          <span class="msg-content" v-else v-html="msg.content.replace(/(?:\r\n|\r|\n)/g, '<br>')"></span>
        </div>
      </div>
    </div>
    <div v-else class="conversation" ref="conversationContainer">
      <div class="assistant">
        <div class="msg-wrapper">
          <span class="msg-role">Loading</span>
          <span class="msg-content ellipsis">...</span>
        </div>
      </div>
    </div>
  </section>
  <section class="entry-area">
    <div class="entry-wrapper">
      <button @click="summarizeEvents">Refresh Data</button>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import ical from 'ical.js';
import { ref, computed, onMounted, toRaw, watch, nextTick } from 'vue';
import { useStore } from 'vuex';
import { systemMessagesUpcoming, upcomingDays } from '@/openai.config';
import { getAssistantResponse } from '@/api/openai';

export default {
  name: 'MyComponent',

  setup() {
    const store = useStore();
    const conversation = computed(() => store.getters.conversation);
    const newMessage = ref('');
    const isLoading = ref(false);
    const messageInput = ref(null);
    const conversationContainer = ref(null); // Add conversationContainer ref
    const endpointForAnnouncements = 'https://www.jesuitnola.org/wp-json/wp/v2/posts?_fields%5B%5D=title&_fields%5B%5D=content&categories=134&per_page=2';
    // const endpointForWebsiteEvents = 'https://www.jesuitnola.org/wp-json/wp/v2/espresso_event?&per_page=15&_fields=title,meta.jesuits_score,meta.opponents_score,meta.win_loss,meta.event_start_date,meta.event_end_date&upcoming=1';
    const endpointsForCalendars = [
      {
        uri: '/assets/calendar.ics',
        label: 'Academic Calendar',
        description: 'This calendar includes all academic events and important academic dates.'
      },
      {
        uri: '/assets/athletics.ics',
        label: 'Sports Calendar',
        description: 'This calendar covers sporting events.'
      },
    ];


    const scrollToBottom = async () => {
      await nextTick(); // Wait for the DOM to update
      window.scrollTo({
        left: 0,
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    };

    watch(conversation, () => {
      scrollToBottom(); // Trigger the scroll to bottom whenever the conversation changes
    }, { deep: true }); // Add deep:true to watch changes in the conversation array

    onMounted(async () => {
      console.log('Upcoming.vue mounted');
      summarizeEvents();
      
    });

    const summarizeEvents = async () => {
      resetConversation();
      isLoading.value = true;
      const announcementData = await fetchAnnouncementData();

      let allEvents = [];
      for (const calendar of endpointsForCalendars) {
        const events = await fetchAndProcessCalendarData(calendar.uri);
        if (events) {
          allEvents.push(...events);
        }
      }

      // Sort events by start date
      allEvents.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());

      // Format sorted events into a string
      const formattedCalendarData = allEvents.map(event => {
        return `Title: ${event.title}, Start Day: ${event.dayOfWeek}, Start Date and Time: ${event.startDateTime}, End Date and Time: ${event.endDateTime}, Notes: ${event.description}`;
      }).join('\n');

      console.log(formattedCalendarData);

      const tempSystemMessages = [
        { role: 'system', content: `CALENDAR EVENTS, PRIORITIZE WRITING ABOUT THESE OVER OTHER SECTIONS: \r\n${formattedCalendarData}` },
        { role: 'system', content: `ANNOUNCEMENT INFORMATION, DO NOT PRIORITIZE: \r\n${announcementData}` },
      ];

      await pushNewMessage(systemMessagesUpcoming, tempSystemMessages);
      isLoading.value = false;
    };


    // const fetchEventData = async () => {
    //   try {
    //     const response = await axios.get(endpointForWebsiteEvents);
    //     return response.data.map(event => `Title: ${event.title.rendered}, Start Date: ${event.meta.event_start_date}, End Date: ${event.meta.event_end_date}`).join('\n');
    //   } catch (error) {
    //     console.error('Error while fetching event data:', error);
    //     return 'Unable to fetch event data';
    //   }
    // };

    const fetchAnnouncementData = async () => {
      try {
        const response = await axios.get(endpointForAnnouncements);
        return response.data.map(announcement => `Title: ${announcement.title.rendered}, Content: ${announcement.content.rendered}`).join('\n');
      } catch (error) {
        console.error('Error while fetching announcement data:', error);
        return 'Unable to fetch announcement data';
      }
    };

    const fetchAndProcessCalendarData = async (calendarUri) => {
      try {
        const response = await fetch(calendarUri);
        const data = await response.text();
        const jCalData = ical.parse(data);
        const vcalendar = new ical.Component(jCalData);
        const events = vcalendar.getAllSubcomponents('vevent');

        // Set 'today' to the start of today's date
        const today = new Date();
        today.setHours(0, 0, 0, 0);  // Reset hours, minutes, seconds, and milliseconds

        // Set 'endDate' to the end of the day, X days from today
        const endDate = new Date();
        endDate.setDate(today.getDate() + upcomingDays);
        endDate.setHours(23, 59, 59, 999);  // Set to the last millisecond of the last day in the range

        return events.map(event => {
          const title = event.getFirstPropertyValue('summary');
          const dtstart = event.getFirstPropertyValue('dtstart');
          const dtend = event.getFirstPropertyValue('dtend');
          const description = event.getFirstPropertyValue('description') || 'No additional information';

          const eventStartDate = dtstart ? new Date(dtstart.year, dtstart.month - 1, dtstart.day, dtstart.hour, dtstart.minute, dtstart.second) : null;
          const eventEndDate = dtend ? new Date(dtend.year, dtend.month - 1, dtend.day, dtend.hour, dtend.minute, dtend.second) : null;

          // Compare event start date to 'today' and 'endDate'
          if (eventStartDate && eventStartDate >= today && eventStartDate <= endDate) {
            return {
              title,
              description,
              startDate: eventStartDate,
              endDate: eventEndDate,
              dayOfWeek: eventStartDate.toLocaleString('en-US', { weekday: 'long' }),
              startDateTime: eventStartDate.toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }),
              endDateTime: eventEndDate ? eventEndDate.toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }) : 'No end date'
            };
          }
          return null;
        }).filter(event => event !== null);
      } catch (error) {
        console.error('Error while fetching or parsing calendar data:', error);
        return [];
      }
    };

    const resetConversation = () => {
      store.dispatch('clearConversation');
    };

    const pushNewMessage = async (systemPrompt, tempSystemMessages) => {
      await nextTick(); // Wait for the DOM to update

      // Create a copy of the conversation without reactivity
      const rawConversation = systemPrompt.concat(toRaw(tempSystemMessages));

      isLoading.value = true;
      const assistantMessage = {
        role: 'assistant',
        content: '',
      };
      const assistantMessageIndex = conversation.value.length;
      store.dispatch('addMessage', assistantMessage);
       try {
        const streamAssistantResponse = async (responseChunk) => {
          store.dispatch('updateMessage', { index: assistantMessageIndex, content: responseChunk });
        };

        await getAssistantResponse(rawConversation, streamAssistantResponse);
      } catch (error) {
        console.error('Error while calling OpenAI API:', error);
      }
      isLoading.value = false;
    };

    return {
      conversation,
      newMessage,
      pushNewMessage,
      resetConversation,
      summarizeEvents,
      isLoading,
      messageInput,
      conversationContainer,
    };
  },
};
</script>

<style>

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  background-color: #444654;
}

.conversation-area {
  width: 100%;
  margin: 0 auto;
}

.conversation {
  width: 100%;
  padding-bottom: 116px;
  margin-bottom: 0;
  overflow-y: auto;
}

.user,
.assistant {
  padding: 2rem;
  width: 100%;
  color: rgb(209, 213, 219);
  margin-bottom: 0.5rem;
  line-height: 1.5;
  font-family: sans-serif;
}

.user .msg-wrapper,
.assistant .msg-wrapper {
  display: flex;
  max-width: 750px;
  margin: 0 auto;
}

.msg-role {
  display: block;
  width: 6rem;
  margin-left: -7rem;
  text-transform: capitalize;
  font-weight: 700;
  color: #ffffff;
  text-align: right;
}

.msg-content {
  display: block;
  margin-left: 1rem;
  width: calc(100% - 6rem);
}

.user {
  background-color: rgb(52, 53, 65);
}

.assistant {
  background-color: rgba(0,0,0,0);
}

@keyframes ellipsis {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ellipsis {
  display: inline-block;
  font-weight: bold;
  animation: ellipsis 1.5s steps(3, start) infinite;
}

.entry-area {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgb(52, 53, 65);
  padding: 1rem;
  padding-bottom: 2rem;
}

.entry-wrapper {
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
}

textarea {
  width: 100%; /* Make textarea 100% wide */
  resize: none; /* Disable resize by dragging the corner */
  overflow: hidden;
  padding: 0.85rem 1rem;
  border: 0;
  border-radius: 6px;
  color: rgb(255,255,255);
  background-color: rgba(255,255,255,0.1);
  appearance: none;
  -webkit-appearance: none;
  outline: 0;
  font-size: 1rem;
  line-height: 1.5;
}
</style>
